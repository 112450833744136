<!--
 * @Descripttion: 报价单
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-26 20:44:36
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-17 23:26:48
-->
<template>
  <div class="page-box">
    <div class="content-box content-box--form">
      <div class="form__title">工单id：{{ info.number }}</div>
      <div>
        <div class="form__row" data-title="联系人">
          {{ info.contactPerson }}
        </div>
        <div class="form__row" data-title="联系电话">
          {{ info.contactMobile }}
        </div>
        <div class="form__row" data-title="店铺名称">{{ info.shopName }}</div>
        <div class="form__row" data-title="门店地址">{{ info.address }}</div>
        <div class="form__row" data-title="品牌区域">
          {{ info.brandAreaName }}
        </div>
      </div>
      <div class="form__comment">
        注：
        <br />
        1. 此费用明细单可作为报价单或收费单使用；
        <br />
        2. 接受此单将被视为自动认同对奕侨公司所负的严格保密义务；
        <br />
        3.
        此费用明细单作为报价单时，其有效期为5个工作日。逾期奕侨公司保留调整价格的权力
      </div>
    </div>

    <div class="content-box" v-for="(item, index) in projects" :key="index">
      <memo-box :title="'维修项目' + (index + 1) + '——' + item.name">
        <div
          class="arrow"
          :class="item.showDetail ? '' : 'arrow--close'"
          slot="header"
          @click="item.showDetail = !item.showDetail"
        ></div>
        <div slot="body" class="price-content">
          <div v-show="item.showDetail">
            <div class="price__title">材料费用</div>
            <div class="price-form">
              <div
                v-for="(material, materialIndex) in item.material.list"
                :key="materialIndex"
              >
                <div class="price-form__row">
                  <div class="price-form__item">
                    {{ material.name }}
                  </div>
                </div>
                <div class="price-form__row">
                  <div class="price-form__item">
                    <span
                      class="price-form__row__name price-form__row__name--required"
                      >数量</span
                    >
                    <input
                      class="price-form__row__input"
                      type="number"
                      placeholder="填写数量"
                      v-number-input
                      v-model="material.number"
                      @input="sumPrice(index)"
                    />
                  </div>
                  <div class="price-form__item">
                    <span
                      class="price-form__row__name price-form__row__name--required"
                      >单位</span
                    >
                    <input
                      class="price-form__row__input"
                      type="text"
                      placeholder="填写单位"
                      v-model="material.unit"
                    />
                  </div>
                </div>
                <div class="price-form__row">
                  <div class="price-form__item">
                    <span
                      class="price-form__row__name price-form__row__name--required"
                      >单价</span
                    >
                    <input
                      class="price-form__row__input"
                      type="number"
                      placeholder="填写费用"
                      v-number-input
                      v-model="material.price"
                      @input="sumPrice(index)"
                    />
                    <span class="price-form__row__num">元</span>
                  </div>
                </div>
              </div>
              <div class="price-form__row">
                <div class="price-form__item">
                  <input
                    type="text"
                    class="form__name"
                    v-model="materialName"
                    placeholder="请输入材料名"
                  />
                </div>
                <div class="price-form__item right">
                  <div class="add-project" @click="addMaterial(index)">
                    新增
                  </div>
                </div>
              </div>
              <div class="price-form__row">
                <div class="price-form__item">
                  <span
                    class="price-form__row__name price-form__row__name--required"
                    >合计</span
                  >
                </div>
                <div class="price-form__item">
                  <input
                    class="price-form__row__input price-form__row__price"
                    type="number"
                    placeholder="填写费用"
                    disabled
                    v-model="item.material.amount"
                  />
                  <span class="price-form__row__num">元</span>
                </div>
              </div>
            </div>
            <div class="price__title">人工费</div>
            <div class="price-form">
              <div
                v-for="(artificial, artificialIndex) in item.artificial"
                :key="artificialIndex"
              >
                <div class="price-form__row">
                  <div class="price-form__item">
                    <span
                      class="price-form__row__name price-form__row__name--required"
                      >工人数</span
                    >
                    <input
                      class="price-form__row__input"
                      type="number"
                      placeholder="填写人数"
                      v-number-input
                      v-model="artificial.people"
                    />
                    <span class="price-form__row__num">人</span>
                  </div>
                  <div class="price-form__item">
                    <span
                      class="price-form__row__name price-form__row__name--required"
                      >工时</span
                    >
                    <input
                      class="price-form__row__input"
                      type="number"
                      placeholder="填写工时"
                      v-number-input
                      v-model="artificial.hour"
                    />
                    <span class="price-form__row__num">小时</span>
                  </div>
                </div>
                <div class="price-form__row">
                  <div class="price-form__item grow">
                    <span
                      class="price-form__row__name price-form__row__name--required"
                      >工作时间段</span
                    >
                    <div class="price-form-tags">
                      <div
                        class="price-form__tag"
                        :class="
                          artificial.period === periodIndex
                            ? 'price-form__tag--active'
                            : ''
                        "
                        v-for="(type, periodIndex) in period"
                        :key="periodIndex"
                        @click="artificial.period = periodIndex"
                      >
                        {{ type }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="price-form__row">
                  <div class="price-form__item">
                    <span
                      class="price-form__row__name price-form__row__name--required"
                      >合计</span
                    >
                  </div>
                  <div class="price-form__item">
                    <input
                      class="price-form__row__input price-form__row__price"
                      type="number"
                      placeholder="填写费用"
                      @input="sumPrice(index)"
                      v-number-input
                      v-model="artificial.amount"
                    />
                    <span class="price-form__row__num">元</span>
                  </div>
                </div>
              </div>
              <div class="price-form__row">
                <div class="price-form__item"></div>
                <div class="price-form__item right">
                  <div class="add-project" @click="addArtificial(index)">
                    新增
                  </div>
                </div>
              </div>
            </div>
            <div class="price__title">其他费用</div>
            <div class="price-form">
              <div class="price-form__row">
                <div class="price-form__item">
                  <span
                    class="price-form__row__name price-form__row__name--required"
                    >市内交通</span
                  >
                  <input
                    class="price-form__row__input"
                    type="number"
                    v-number-input
                    placeholder="填写价格"
                    @input="sumPrice(index)"
                    v-model="item.other.traffic"
                  />
                  <span class="price-form__row__num">元</span>
                </div>
                <div class="price-form__item">
                  <span
                    class="price-form__row__name price-form__row__name--required"
                    >出差差旅</span
                  >
                  <input
                    class="price-form__row__input"
                    type="number"
                    v-number-input
                    placeholder="填写价格"
                    @input="sumPrice(index)"
                    v-model="item.other.business"
                  />
                  <span class="price-form__row__num">元</span>
                </div>
              </div>
              <div class="price-form__row">
                <div class="price-form__item">
                  <span
                    class="price-form__row__name price-form__row__name--required"
                    >材料运输</span
                  >
                  <input
                    class="price-form__row__input"
                    type="number"
                    v-number-input
                    placeholder="填写价格"
                    @input="sumPrice(index)"
                    v-model="item.other.materialTransportation"
                  />
                  <span class="price-form__row__num">元</span>
                </div>
                <div class="price-form__item">
                  <span
                    class="price-form__row__name price-form__row__name--required"
                    >现场细清</span
                  >
                  <input
                    class="price-form__row__input"
                    type="number"
                    v-number-input
                    placeholder="填写价格"
                    @input="sumPrice(index)"
                    v-model="item.other.scene"
                  />
                  <span class="price-form__row__num">元</span>
                </div>
              </div>
              <div class="price-form__row">
                <div class="price-form__item">
                  <span
                    class="price-form__row__name price-form__row__name--required"
                    >施工保险</span
                  >
                  <input
                    class="price-form__row__input"
                    type="number"
                    v-number-input
                    placeholder="填写价格"
                    @input="sumPrice(index)"
                    v-model="item.other.constructionInsurance"
                  />
                  <span class="price-form__row__num">元</span>
                </div>
                <div class="price-form__item">
                  <span
                    class="price-form__row__name price-form__row__name--required"
                    >其他费用</span
                  >
                  <input
                    class="price-form__row__input"
                    type="number"
                    v-number-input
                    placeholder="填写价格"
                    v-model="item.other.other"
                    @input="sumPrice(index)"
                  />
                  <span class="price-form__row__num">元</span>
                </div>
              </div>
              <div class="price-form__row">
                <div class="price-form__item">
                  <span
                    class="price-form__row__name price-form__row__name--required"
                    >合计</span
                  >
                </div>
                <div class="price-form__item">
                  <input
                    class="price-form__row__input price-form__row__price"
                    type="number"
                    placeholder="填写费用"
                    v-model="item.other.amount"
                    disabled
                  />
                  <span class="price-form__row__num">元</span>
                </div>
              </div>
            </div>
          </div>
          <div class="subtotal">
            <div class="subtotal__title">小计</div>
            <div class="subtotal__price">{{ item.amount }}</div>
          </div>
        </div>
      </memo-box>
    </div>

    <div class="content-box content-box--add flex-x center">
      <input
        type="text"
        class="form__name grow"
        v-model="addTitle"
        placeholder="请输入项目标题"
      />
      <div class="add-project" @click="addProject">新增</div>
    </div>

    <div class="content-box content-box--area flex-x center">
      <textarea
        v-model="remark"
        cols="30"
        rows="10"
        placeholder="请输入备注"
      ></textarea>
    </div>

    <div class="content-box content-box--bottom">
      <div>
        <div class="form__row" data-title="分项合计">{{ projectSum }} 元</div>
        <div
          class="form__row"
          :data-title="'综合管理费(' + managementFeePercent + '%)'"
        >
          {{ managementFee }} 元
        </div>
        <!-- <div class="form__row" :data-title="'税金(' + taxesPercent + '%)'">
          {{ taxes }} 元
        </div> -->
      </div>
      <div class="total">
        <div class="total__title">总额</div>
        <div class="total__price">{{ sum }}</div>
      </div>
    </div>
    <div class="btns flex-x center between">
      <div class="btn btn--confirm" @click="pass">提交报价</div>
    </div>
  </div>
</template>

<script>
import MemoBox from "@/components/MemoBox/MemoBox";
import { orderDetail, submitQuotation } from "../../../utils/api/service";
import { Toast } from "vant";
import { orderConfig } from "../../../utils/api/utils";
export default {
  components: {
    "memo-box": MemoBox,
  },

  data() {
    return {
      showTime: true,

      addTitle: "", // 新增项目的标题

      period: ["白工", "夜工"],

      projects: [],

      info: {},

      projectSum: 0, // 分项合计
      managementFee: 0, // 管理费
      managementFeePercent: 0, // 管理费率
      taxes: 0, // 税金
      taxesPercent: 0, // 税金费率
      sum: 0, // 总额

      remark: "", // 备注

      materialName: "", // 材料名字
    };
  },

  created() {
    orderConfig().then((res) => {
      this.managementFeePercent = res.data.data.managementFee;
      this.taxesPercent = res.data.data.taxes;
      orderDetail({
        id: this.$route.query.id,
      })
        .then((res) => {
          this.info = res.data.data;
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    });
  },

  methods: {
    // 新增项目
    addProject() {
      if (!this.addTitle) return Toast("请先输入项目标题");

      this.projects.push({
        name: this.addTitle,
        showDetail: true,
        amount: 0,
        material: {
          list: [],
          amount: 0,
        },
        artificial: [
          {
            people: 1,
            hour: 1,
            period: 0,
            amount: 0,
          },
        ],
        other: {
          traffic: 0,
          business: 0,
          constructionInsurance: 0,
          scene: 0,
          materialTransportation: 0,
          other: 0,
          amount: 0,
        },
      });

      this.addTitle = "";
    },

    // 新增材料
    addMaterial(projectIndex) {
      if (!this.materialName) return Toast("请输入材料名");

      let project = JSON.parse(JSON.stringify(this.projects[projectIndex]));

      project.material.list.push({
        number: 1,
        price: 0,
        unit: "个",
        name: this.materialName,
      });

      this.materialName = "";

      this.$set(this.projects, projectIndex, project);
    },

    // 新增人工费
    addArtificial(projectIndex) {
      let project = JSON.parse(JSON.stringify(this.projects[projectIndex]));

      project.artificial.push({
        people: 1,
        hour: 1,
        period: 0,
        amount: 0,
      });

      this.$set(this.projects, projectIndex, project);
    },

    // 通过报价
    pass() {
      let projects = JSON.parse(JSON.stringify(this.projects));
      projects.forEach((item) => {
        item.material = item.material.list;
        item.material.forEach((item) => {
          item.unit_price = item.price;
          delete item.price;
        });
        item.other.material_transportation = item.other.materialTransportation;
        item.other.construction_insurance = item.other.constructionInsurance;
        delete item.other.materialTransportation;
        delete item.other.constructionInsurance;
      });
      submitQuotation({
        id: this.$route.query.id,
        serviceProject: projects,
        remark: this.remark,
      })
        .then((res) => {
          Toast.success({
            message: "报价成功",
            onClose: function () {
              this.$router.back();
            }.bind(this),
          });
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 计算总价
    sumPrice(index) {
      let projects = this.projects,
        project = JSON.parse(JSON.stringify(projects[index]));

      // 计算材料费用
      project.material.amount = 0;
      project.material.list.forEach((item) => {
        project.material.amount += item.number * item.price;
      });

      // 计算人工费
      let artificialTotal = 0;
      project.artificial.forEach((item) => {
        artificialTotal += parseFloat(item.amount);
      });

      // 计算其他费用
      project.other.amount =
        parseFloat(project.other.traffic) +
        parseFloat(project.other.business) +
        parseFloat(project.other.materialTransportation) +
        parseFloat(project.other.scene) +
        parseFloat(project.other.constructionInsurance) +
        parseFloat(project.other.other);

      // 计算小计
      project.amount =
        project.material.amount + project.other.amount + artificialTotal;
      if (isNaN(project.amount)) project.amount = 0;

      this.$set(this.projects, index, project);

      this.countSum();
    },

    // 计算总额 分项合计 综合管理费 税金
    countSum() {
      let projects = this.projects;
      // 分项合计
      let projectSum = 0;
      for (let i = 0; i < projects.length; i++) {
        projectSum += parseFloat(projects[i].amount);
      }
      this.projectSum = projectSum;
      // 综合管理费
      let managementFee = projectSum * (this.managementFeePercent / 100);
      this.managementFee = managementFee.toFixed(2);
      // 税金
      let taxes = projectSum * (this.taxesPercent / 100);
      this.taxes = taxes.toFixed(2);
      // 总额
      let sum = projectSum + managementFee + taxes;
      this.sum = sum.toFixed(2);
    },
  },
};
</script>

<style src="../../../assets/css/review/Offer.css" scoped>
</style>

<style src="../../../assets/css/service/offer.css" scoped>
</style>